.table-wrap {
  margin: 1%;
}

table {
  margin: 20px auto;
  border-spacing: 0;
  border-collapse: collapse;
}

td {
  border: 1px solid grey;
  padding: 9px;
  min-width: 50px;
  text-align: center;
}

.tableRes {
  font-weight: bold;
}

tfoot tr td {
  font-weight: bold;
}

tbody tr td {
  cursor: pointer;
}

.activ {
  color: red;
  font-weight: bold;
}

.del {
  color: #fff;
  background-color: #f50057;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.0286em;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.del:hover {
  background-color: #c51162;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
