.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.demoForm {
  padding-top: 15%;
}

.buttonAdd {
  margin: "auto";
}
